import * as React from 'react'
import * as ReactRouter from 'react-router-dom'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import * as Herz from '@rushplay/herz'

import * as CombinedSelectors from './combined-selectors'

export function PrivateRoute({ component: Component, ...props }) {
  const { authenticated } = Herz.Auth.useSession()
  const { pathname } = ReactRouter.useLocation()
  const isPhoneVerificationRequired = CombinedSelectors.useIsPhoneVerificationRequired()

  return (
    <Route
      {...props}
      render={({ ...props }) => {
        if (authenticated && !isPhoneVerificationRequired) {
          return <Component {...props} />
        }

        return <ReactRouter.Redirect from={pathname} to="/" />
      }}
    />
  )
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.object,
  ]),
  path: PropTypes.string,
}

import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import React from 'react'

import * as Common from '@rushplay/common'
import * as Herz from '@rushplay/herz'
import styled from '@emotion/styled'
import { css } from '@styled-system/css'

import * as Configuration from './configuration'
import { ApgSeal } from './apg-seal'
import { AppVersion } from './app-version'
import { ClientType } from './constants'
import { HtmlContent } from './html-content'
import { SocialMediaIcons } from './social-media-icons'
import { XcmSeal } from './xcm-seal'

const Container = styled(Common.Box)`
  display: grid;
  width: 100%;

  margin: 0 auto;
  grid-template-areas:
    'logo'
    'nav'
    'socials';
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  @media (min-width: 768px) {
    grid-template-areas:
      'logo nav'
      'socials nav';
    max-width: 1440px;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: auto auto;
  }
`

const LogoContainer = styled.div`
  grid-area: logo;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 15px;
  ${css({
    p: [1, '0px'],
    pb: ['0px', 1],
  })}
`

const FooterNav = styled.nav`
  grid-area: nav;
  display: grid;
  font-size: 14px;
  align-items: flex-start;
  grid-auto-rows: auto;
  ${css({
    gridTemplateColumns: [
      'repeat(2, 1fr)',
      'repeat(4, 1fr)',
      'repeat(4, 1fr)',
      'repeat(4, 1fr)',
    ],
  })}
`

const SocialsContainer = styled.div`
  gridarea: socials;
  display: flex;
  justify-content: center;
  align-items: center;
  ${css({
    py: [0, null, null, '0px'],
  })}
`

const Link = styled(ReactRouter.Link)`
  display: flex;
  flex: 1;
  color: #b2c2ee;
  align-items: center;
  ${css({
    minWidth: [null, null, null, '250px'],
    justifyContent: ['center', 'flex-start', 'flex-start', 'flex-start'],
    textAlign: ['center', 'left', 'left', 'left'],
  })}
  @media (max-width: 768px) {
    &:last-child:nth-of-type(odd) {
      grid-column: span 2;
      justify-self: center;
    }
  }
`

const LinkWrapper = styled.div`
  ${css({
    p: 1,
  })}
  opacity: 0.6;
  transition: opacity 75ms ease-in;

  &:hover,
  &:active {
    opacity: 0.9;
  }
`

const translationKeys = [
  'footer.terms-and-conditions',
  'footer.about-us',
  'footer.cookie-policy',
  'footer.faq',
  'footer.privacy-policy',
  'footer.responsible-gaming',
  'footer.security',
  'footer.affiliate',
  'footer.legal-text.curacao',
  '18-plus',
  'sweetspot-logo',
  'footer.self-exclusion',
  'footer.accounts-payouts-bonuses',
  'footer.rules',
]

const Logotype = styled('img')`
  ${css({
    width: ['100%', null, null, '264px'],
    height: 'auto',
    maxWidth: '400px',
  })};
`

export function Footer() {
  const translate = Herz.I18n.useTranslate()

  const location = ReactRouter.useLocation()
  const isGameplay = location.pathname.includes('/games')

  const clientType = ReactRedux.useSelector(state =>
    Configuration.getClientType(state.configuration)
  )
  const isMobile = ClientType.MOBILE_BROWSER === clientType

  /**
   * Hides the footer:
   * - When playing any game on mobile
   */

  if (isMobile && isGameplay) {
    return null
  }

  return (
    <Common.Box
      display="flex"
      backgroundColor="footer-background"
      padding={[1, 2]}
      mb={['80px', '0px', '0px', '0px']}
      flexDirection="column"
      alignItems="center"
      as="footer"
    >
      <Container>
        <LogoContainer>
          <Logotype src="/images/logo.svg" />
        </LogoContainer>
        <FooterNav>
          <Link to="/terms-and-conditions" data-testid="footer.link.terms">
            <LinkWrapper>
              {translate('footer.terms-and-conditions')}
            </LinkWrapper>
          </Link>

          <Link to="/rules" data-testid="footer.link.rules">
            <LinkWrapper>{translate('footer.rules')}</LinkWrapper>
          </Link>

          <Link to="/about" data-testid="footer.link.about">
            <LinkWrapper>{translate('footer.about-us')}</LinkWrapper>
          </Link>
          <Link to="/cookie-policy" data-testid="footer.link.cookie-policy">
            <LinkWrapper>{translate('footer.cookie-policy')}</LinkWrapper>
          </Link>
          <Link to="/faq" data-testid="footer.link.faq">
            <LinkWrapper>{translate('footer.faq')}</LinkWrapper>
          </Link>
          <Link to="/privacy-policy" data-testid="footer.link.privacy-policy">
            <LinkWrapper>{translate('footer.privacy-policy')}</LinkWrapper>
          </Link>
          <Link
            to="/responsible-gaming"
            data-testid="footer.link.responsible-gaming"
          >
            <LinkWrapper>{translate('footer.responsible-gaming')}</LinkWrapper>
          </Link>
          {/* <Link
          to="/self-exclusion"
          data-testid="footer.link.self-exclusion"
        >
          <LinkWrapper>{translate('footer.self-exclusion')}</LinkWrapper>
        </Link> */}
          {/* <Link
          to="/accounts-payouts-bonuses"
          data-testid="footer.link.accounts-payouts-bonuses"
        >
          <LinkWrapper>
            {translate('footer.accounts-payouts-bonuses')}
          </LinkWrapper>
        </.Link> */}
        </FooterNav>
        <SocialsContainer>
          <SocialMediaIcons />
        </SocialsContainer>
      </Container>

      <Common.Box
        fontSize={1}
        color="#f3e3d8"
        opacity="0.5"
        pt={[null, null, null, 2]}
        textAlign="center"
        mx="auto"
        maxWidth="1440px"
      >
        <HtmlContent
          as="small"
          html={{
            __html: translate('footer.legal-text.curacao'),
          }}
        />
      </Common.Box>

      <Common.Box
        display="flex"
        pt={2}
        justifyContent="center"
        alignItems="center"
      >
        <Common.Box px={1}>
          <XcmSeal />
        </Common.Box>
        <ApgSeal />
        <ReactRouter.Link to="/responsible-gaming">
          <Common.Box
            as="img"
            alt="18+"
            src={translate('18-plus')}
            height="34px"
            mx={1}
          />
        </ReactRouter.Link>
        <a target="_blank" rel="noreferrer" href="https://www.rgf.org.mt/">
          <Common.Box
            as="img"
            alt="Responsible"
            src={
              'https://static.wixstatic.com/media/7caa94_c4f804f5d4804b60b28c762cf79740ae~mv2.png/v1/fill/w_340,h_122,al_c,lg_1,q_85,enc_auto/Untitled-1.png'
            }
            height="34px"
            mx={1}
          />
        </a>
      </Common.Box>

      <Common.Box textAlign="center">
        <AppVersion />
      </Common.Box>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(translationKeys, Footer)

// for @loadable/components
export default Footer

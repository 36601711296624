import * as React from 'react'

import * as Common from '@rushplay/common'

export function AppVersion() {
  if (process.env.RAZZLE_APP_VERSION) {
    return (
      <Common.Box color="#f3e3d8" fontFamily="Raleway" fontSize={0} p={1}>
        {process.env.RAZZLE_APP_VERSION}
      </Common.Box>
    )
  }

  return null
}

import * as Constants from './constants'
import { useSafeUpdateQuery } from './use-safe-update-query'

export function useMenuQueries() {
  const depositQuery = useSafeUpdateQuery({
    wallet: Constants.TransactionType.PURCHASE,
    menu: null,
    'my-profile': null,
    'change-password': null,
    'transaction-history': null,
  })

  const withdrawalQuery = useSafeUpdateQuery({
    wallet: Constants.TransactionType.REDEEM,
    menu: null,
    'my-profile': null,
    'change-password': null,
    'transaction-history': null,
  })

  const loginQuery = useSafeUpdateQuery({
    login: 'me',
    menu: null,
    'responsible-gaming': null,
    'my-profile': null,
    'change-password': null,
    'reset-password': null,
  })
  const registerQuery = useSafeUpdateQuery({
    register: 'me',
    menu: null,
    'responsible-gaming': null,
    'my-profile': null,
    'change-password': null,
  })

  const menuQuery = useSafeUpdateQuery({
    menu: 'me',
    register: null,
    login: null,
    wallet: null,
    'responsible-gaming': null,
    'my-profile': null,
    'change-password': null,
    'transaction-history': null,
  })

  const promotionQuery = useSafeUpdateQuery({
    promotions: 'campaign',
    menu: null,
    register: null,
    login: null,
    wallet: null,
    'responsible-gaming': null,
    'my-profile': null,
    'change-password': null,
    'transaction-history': null,
  })
  const purchaseLimitQuery = useSafeUpdateQuery({
    'responsible-gaming': 'limit',
    menu: null,
    register: null,
    login: null,
    wallet: null,
    'my-profile': null,
    'change-password': null,
    'transaction-history': null,
  })

  const timeoutQuery = useSafeUpdateQuery({
    'responsible-gaming': 'time-out',
    menu: null,
    register: null,
    login: null,
    wallet: null,
    'my-profile': null,
    'change-password': null,
    'transaction-history': null,
  })

  const myProfileQuery = useSafeUpdateQuery({
    'my-profile': 'me',
    'responsible-gaming': null,
    menu: null,
    register: null,
    login: null,
    wallet: null,
    'change-password': null,
    'transaction-history': null,
  })

  const changePasswordQuery = useSafeUpdateQuery({
    'change-password': 'me',
    'my-profile': null,
    'responsible-gaming': null,
    menu: null,
    register: null,
    login: null,
    wallet: null,
    'transaction-history': null,
  })

  const realityCheckQuery = useSafeUpdateQuery({
    'responsible-gaming': 'reality-check',
    'transaction-history': null,
    menu: null,
    register: null,
    login: null,
    wallet: null,
  })

  const gamingHistoryQuery = useSafeUpdateQuery({
    'gaming-history': 'me',
    'transaction-history': null,
    'change-password': null,
    'my-profile': null,
    'responsible-gaming': null,
    menu: null,
    register: null,
    login: null,
    wallet: null,
  })

  const transactionHistoryQuery = useSafeUpdateQuery({
    'transaction-history': 'me',
    'gaming-history': null,
    'change-password': null,
    'my-profile': null,
    'responsible-gaming': null,
    menu: null,
    register: null,
    login: null,
    wallet: null,
  })

  const resetPasswordQuery = useSafeUpdateQuery({
    'reset-password': 'me',
    menu: null,
    register: null,
    login: null,
    wallet: null,
  })

  return {
    depositQuery,
    loginQuery,
    registerQuery,
    withdrawalQuery,
    menuQuery,
    promotionQuery,
    purchaseLimitQuery,
    timeoutQuery,
    realityCheckQuery,
    myProfileQuery,
    changePasswordQuery,
    gamingHistoryQuery,
    transactionHistoryQuery,
    resetPasswordQuery,
  }
}
